import { render, staticRenderFns } from "./DayTabs.vue?vue&type=template&id=79c4d698&scoped=true&"
import script from "./DayTabs.vue?vue&type=script&lang=js&"
export * from "./DayTabs.vue?vue&type=script&lang=js&"
import style1 from "./DayTabs.vue?vue&type=style&index=1&id=79c4d698&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79c4d698",
  null
  
)

export default component.exports