import { render, staticRenderFns } from "./BuildingMap.vue?vue&type=template&id=2e39cd54&scoped=true&"
import script from "./BuildingMap.vue?vue&type=script&lang=js&"
export * from "./BuildingMap.vue?vue&type=script&lang=js&"
import style0 from "./BuildingMap.vue?vue&type=style&index=0&id=2e39cd54&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e39cd54",
  null
  
)

export default component.exports