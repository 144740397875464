// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/campus.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.map-container[data-v-377f17c4] {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  margin-left: auto;\n  margin-right: auto;\n}\n@media (min-width: 960px) {\n.map-container[data-v-377f17c4] {\n  max-width: calc(100vh - 220px * 990 / 1386);\n  margin-left: 0;\n  margin-right: 0;\n}}\n@media (min-aspect-ratio: 1/1) and (max-height: 550px) {\n.map-container[data-v-377f17c4] {\n    max-width: calc(69% - 65px);\n}\n}\n@media (min-aspect-ratio: 16/9) and (max-height: 550px) {\n.map-container[data-v-377f17c4] {\n    max-width: calc(54% - 65px);\n}\n}\n.map[data-v-377f17c4] {\n  position: relative;\n  width: 100%;\n  height: 0;\n  padding-top: calc(990 / 1386 * 100%);\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n}\n.map a[data-v-377f17c4] {\n  position: absolute;\n}\n#building-k[data-v-377f17c4] {\n  top: 27%;\n  left: 21.5%;\n  width: 11.5%;\n  height: 10%;\n}\n#building-j[data-v-377f17c4] {\n  top: 67.5%;\n  left: 32%;\n  width: 11%;\n  height: 15.5%;\n}\n#building-h[data-v-377f17c4] {\n  top: 59%;\n  left: 43.0%;\n  width: 6.5%;\n  height: 23%;\n}\n#building-aw[data-v-377f17c4] {\n  top: 65%;\n  left: 53.5%;\n  width: 6.5%;\n  height: 9.5%;\n}\n#building-u[data-v-377f17c4] {\n  top: 43.5%;\n  left: 51.5%;\n  width: 26.5%;\n  height: 11.5%;\n}\n", ""]);
// Exports
module.exports = exports;
