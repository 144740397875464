// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/menu-logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.updown[data-v-3c02d06b] {\n  width: 36px !important;\n}\n@media only screen and (min-width:960px) {\n.v-icon[data-v-3c02d06b], .v-btn[data-v-3c02d06b], .v-toolbar[data-v-3c02d06b] {\n    transition: none !important;\n}\n.v-app-bar[data-v-3c02d06b] {\n    background-color: var(--v-primary-base);\n    color: white;\n    font-size: 18px;\n    font-weight: normal;\n    line-height: 33px;\n    user-select: none;\n}\n.layout-default .v-app-bar[data-v-3c02d06b],\n  .layout-default .v-btn[data-v-3c02d06b] {\n    background-color: white;\n    color: var(--grey-darken-4);\n}\n.row[data-v-3c02d06b] {\n    max-width: 960px;\n}\n.logo[data-v-3c02d06b] {\n    background-color: transparent !important;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: left center;\n    background-size: auto 26px;\n    color: white;\n    font-weight: bold;\n    padding-left: 32px;\n}\n.layout-default .logo[data-v-3c02d06b] {\n    filter: invert(0.9);\n}\n.v-btn[data-v-3c02d06b] {\n    font-size: 18px;\n    font-weight: normal;\n    text-transform: none;\n    padding-left: 15px;\n    padding-right: 15px;\n    min-width: 0;\n}\n.v-btn[data-v-3c02d06b]::before {\n    background-color: initial;\n    opacity: 1.0 !important;\n    width: 0;\n    transition: width 0.15s ease-in;\n}\n.v-btn--active[data-v-3c02d06b]::before {\n    border-bottom: 2px solid var(--v-secondary-base);\n    border-radius: unset;\n    width: 28px;\n    left: calc(50% - 14px);\n    /*width: calc(100% - 54px);\n    left: 27px;*/\n}\n}\n", ""]);
// Exports
module.exports = exports;
