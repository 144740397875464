import { render, staticRenderFns } from "./SharedEvents.vue?vue&type=template&id=80600c2e&scoped=true&"
import script from "./SharedEvents.vue?vue&type=script&lang=js&"
export * from "./SharedEvents.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80600c2e",
  null
  
)

export default component.exports